//DS24 Vars for Orderform
@import 'scss/variables';
@import 'scss/primeng';

//@import '~bootstrap/scss/bootstrap-reboot';
@import 'bootstrap/scss/functions';

/**
 * Overwrite sizes from bootstrap
 */
$small-font-size: 75% !default;

/**
 * Overwrite gray layers from bootstrap
 */
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: $ds-color-grey !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: $ds-color-black !default;

@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

/**
 * @import '~bootstrap/scss/utilities';
 */
@import 'bootstrap/scss/utilities/align';
//@import "~bootstrap/scss/utilities/background";
//@import "~bootstrap/scss/utilities/borders";
//@import "~bootstrap/scss/utilities/clearfix";
@import 'bootstrap/scss/utilities/display';
//@import "~bootstrap/scss/utilities/embed";
@import 'bootstrap/scss/utilities/flex';
@import 'bootstrap/scss/utilities/float';
@import 'bootstrap/scss/utilities/interactions';
@import 'bootstrap/scss/utilities/overflow';
@import 'bootstrap/scss/utilities/position';
//@import "~bootstrap/scss/utilities/screenreaders";
//@import "~bootstrap/scss/utilities/shadows";
@import 'bootstrap/scss/utilities/sizing';
@import 'bootstrap/scss/utilities/spacing';
//@import "~bootstrap/scss/utilities/stretched-link";
@import 'bootstrap/scss/utilities/text';
@import 'bootstrap/scss/utilities/visibility';

@import 'material-icons';

html {
  font-size: 100% !important;
}

ds-orderform-wrapper {
  .alert {
    padding: 7px 15px;
    margin: 6px 0 10px 0;
  }

  .alert {
    border-radius: 2px;
  }
}

.d-flex {
  display: flex;
  margin: 0 -8px;

  > * {
    margin: 0 8px;
  }

  > *.grow1 {
    //flex: 1 1 auto; //for IE11 because it do not understand flex-grow: 1;
    min-width: 1px; // IE 11 Fix

    &.label-container {
      min-width: 30%;
    }
  }

  $sizes: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;
  @each $size in $sizes {
    .grow#{$size} {
      flex: #{$size};
    }
  }

  > *.grow-auto {
    width: 100%;
  }
}

.label-container {
  @media (min-width: 768px) {
    text-align: right;
  }

  label {
    &.label-inherit {
      font-weight: inherit;
    }
  }
}

.label-container {
  text-align: left;
}

label {
  input[type='checkbox'] {
    @extend .mr-2;
  }
}

p {
  margin-bottom: 10px;
}

ds-input-errors {
  text-align: left;
  padding: 6px 0;
}

.cursor-pointer {
  cursor: pointer;
}

.text-150 {
  font-size: 150%;
}

.text-bold {
  font-weight: bold;
}

.amount {
  white-space: nowrap;

  &.wo_discount {
    text-decoration: line-through;
  }

  &.discounted {
    font-weight: bold;
  }
}

body {
  /**
   * Overwrite PGB CSS
   */
  small,
  .small {
    font-size: $small-font-size;
  }

  h4 {
    // important flag is neccessary due to global #page h4 definition
    color: $ds-color-dark-grey !important;
    text-transform: uppercase;
  }

  fieldset {
    margin-bottom: 10px;

    legend {
      display: inline-block;
      width: 100%;
      border: 0;
      margin-bottom: 8px;
      font-size: 14px;
      text-transform: uppercase;
      color: $ds-color-dark-grey;
      font-weight: 700;
    }
  }

  label {
    &.label-inherit {
      font-weight: inherit;
    }
  }

  .list-force {
    list-style: inherit;
    padding: inherit;
    margin: inherit;
    margin-left: 26px;
    width: calc(100% - 26px);
  }

  .text-muted {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $ds-color-medium-grey;
    }
  }

  .success-message {
    color: $ds-color-green;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 0, 587;
    stroke-dashoffset: 0;
  }
  50% {
    stroke: #ddd;
  }
  100% {
    stroke-dasharray: 587, 587;
    stroke-dashoffset: 0;
  }
}

@keyframes dash2 {
  0% {
    stroke-dasharray: 0, 587;
    stroke-dashoffset: 0;
    stroke: #ddd;
  }
  25% {
    stroke: #3988e3;
    stroke-dasharray: 300, 587;
  }
  100% {
    stroke-dasharray: 558, 587;
    stroke-dashoffset: 0;
    stroke: #3988e3;
  }
}

.waiting-layer {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: fixed;
  left: 0;
  top: 0; // menu should be visible!
  bottom: 0;
  right: 0;
  overflow: hidden;
  transition: background-color 500ms;
  //background-color: rgba(100, 100, 100, 0);

  &.visible {
    cursor: wait;
    z-index: 999;
    display: flex;
    //background-color: rgba(100, 100, 100, 0.6);
  }

  .box {
    background-color: $ds-color-white;
    border-radius: 25px;
    // padding: 131px;
    box-shadow: 0 0 9px -5px #000;

    width: 300px;
    // height: 150px;

    display: flex;

    .logo {
      height: 150px;
      width: 109px;
      background-size: 70%;
      background-repeat: no-repeat;
      background-position: center;
      margin-left: 20px;
    }

    .message {
      flex: 1;
      margin: auto 20px auto 0;
    }
  }

  .spinner {
    padding: 5px;
    height: 150px;
    width: 300px;
    // animation: rotate 2s linear infinite;
    transform-origin: center center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .spinner .path {
    stroke-dasharray: 0, 587;
    stroke-dashoffset: 0;
    animation: dash 3s ease-out 1;
    animation-fill-mode: forwards;
    stroke-linecap: round;
    stroke: #ddd;
    // transform: rotate(-90deg);
    // transform-origin: 65px 65px;
  }

  .spinner .path2 {
    stroke-dasharray: 0, 587;
    stroke-dashoffset: 0;
    animation: dash2 4s ease-out 1;
    animation-fill-mode: forwards;
    animation-delay: 3s;
    stroke-linecap: round;
    stroke: #ddd;
    // transform: rotate(-90deg);
    // transform-origin: 65px 65px;
  }
}

/**
 * Toaster
 */
ds24-toasts {
  position: fixed;
  top: 10px;
  z-index: 9999;
  width: 100%;
  display: flex;
  flex-direction: column;

  /* wird benötigt dass man nicht auf den container der toasts klickt */
  height: 0;

  ds24-toast {
    display: inline-block;
    max-width: 95%;
  }
}

/**
 * redesign styles
 */

@import 'scss/forms';
@import 'scss/buttons';
@import 'scss/radio-checkbox';
@import 'scss/widgets';
@import 'scss/autofill-hook';

/**
 * @TODO: remove me after tailwindcss switch
 */
.flex {
  display: flex;
}

.loading-container {
  display: flex;
  align-items: center;

  .loader {
    width: 16px;
    height: 16px;
    border: 2px solid $ds-color-blue;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin-right: 7px;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
