@import 'variables';

//Deeper style for PGB overwrite CSS without !important
#page, // do not use #page (PGB HTML Element)
.force-style {
  /**
   * Form styles & Form validation styles
   */
  .form-control {
    border: 1px solid $ds-color-light-grey;
    box-shadow: 0 2px 4px 0 #04253e29, 0 0 1px 0 #04253e14;
    color: $ds-color-dark-grey;
    height: inherit;
    font-size: 1em;
    padding: $formControlVerticalPadding $formControlHorizontalPadding;
    background-color: $ds-color-white;
    border-radius: 4px;
    outline: none;
    max-width: 100%;
  }

  select.form-control {
    padding: $formControlVerticalPadding 0 $formControlVerticalPadding 8px;
  }

  input.form-control,
  .form-control input {
    &::placeholder,
    &::-ms-input-placeholder,
    &::-webkit-input-placeholder {
      color: $ds-color-grey;
    }

    &:focus {
      border-color: $ds-color-blue;
    }
  }

  ._touched {
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 1.25em 1.25em;
  }

  ._invalid {
    border: 1px solid rgba(209, 101, 79, 1);
    background-image: url('../icons/invalid-input.svg');
  }

  ._valid {
    background-image: url('../icons/valid-input.svg');
  }

  div.form-control,
  input.form-control,
  textarea.form-control,
    // p-calendar, // do not use p-calendar there because input very different input structure!
  p-dropdown.form-control {
    padding-right: $validationIconPaddingRight;

    &.ng-touched.ng-dirty {
      @extend ._touched;

      &.ng-invalid {
        @extend ._invalid;
      }

      &.ng-valid {
        @extend ._valid;
      }
    }
  }

  //dropdowns trigger fix position by error icon
  p-dropdown.form-control.ng-touched.ng-dirty {
    .p-dropdown-trigger {
      padding-right: $formControlHorizontalPadding * 2;
    }

    // no validation, no additional padding für dropdown trigger
    &.form-control-no-validation .p-dropdown-trigger {
      padding-right: 0;
    }
  }

  // handling p-calendar validation...
  p-calendar {
    input.form-control {
      padding-right: $validationIconPaddingRight;
    }

    &.ng-touched.ng-dirty {
      input.form-control {
        @extend ._touched;
      }

      &.ng-invalid {
        input.form-control {
          @extend ._invalid;
        }
      }

      &.ng-valid {
        input.form-control {
          @extend ._valid;
        }
      }
    }
  }

  .form-control[readonly],
  .form-control[disabled] {
    color: $ds-color-grey;
    background-color: $ds-color-very-light-grey;
  }

  .form-control.form-control-no-validation {
    padding-right: $formControlHorizontalPadding !important;
    background-image: none !important;
  }

  input[type='number'].form-control.form-control-no-validation {
    padding-right: 0 !important;
  }

  /**
   * Other styles
   */

  label {
    font-weight: normal;
    font-size: 0.75em;
    color: $ds-color-dark-grey;

    &.required::after {
      content: '*';
      margin-left: -2px;
    }
  }

  .alert.error-container,
  .error-container {
    border-radius: 4px;
    border-width: 0;
    background-color: $ds-color-light-red-trans;
    color: $ds-color-light-red;
    margin: 0 0 10px;
    line-height: 1.5em;
    padding: 8px 8px 8px 10px;
  }
}
